import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Identification.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "react-bootstrap";
import { BsCalendar } from "react-icons/bs";

import {
  createIdentification_stage1_API,
  getAllCountryDataAPI,
  getIdentification_stage1_API,
} from "../../../../api";

import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const Identification_M = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      try {
        const response = await getAllCountryDataAPI();
        if (response.data?.response && response.data?.response?.data) {
          setCountriesData(response.data.response.data);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [passportCountry, setPassportCountry] = useState("");
  const [placeOfissue, setPlaceOfissue] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportExpiryDate, setPassportExpiryDate] = useState(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/middle_entry/stage_1/contact_details", {
      state: { pointerData },
    });
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Contact Details form page.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/middle_entry/stage_1/contact_details", {
      state: { pointerData },
    });
    // toast.success("Navigated to Contact Details form page.");
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = async () => {
    // navigate("/user/dashboard");
    // setShowConfirmationModal(false);
    // toast.success("Identification Details saved successfully.");

    // const formattedPassportExpiryDate =
    //   passportExpiryDate &&
    //   `${passportExpiryDate.getDate()}/${
    //     passportExpiryDate.getMonth() + 1
    //   }/${passportExpiryDate.getFullYear()}`;

    const formattedPassportExpiryDate = passportExpiryDate
      ? new Date(passportExpiryDate).getDate().toString().padStart(2, "0") +
        "/" +
        (new Date(passportExpiryDate).getMonth() + 1)
          .toString()
          .padStart(2, "0") +
        "/" +
        new Date(passportExpiryDate).getFullYear()
      : "";

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData);
    formData.append("country_of_birth", countryOfBirth);
    formData.append("passport_country", passportCountry);
    formData.append("place_of_birth", "");
    formData.append("current_citizenship", "");
    formData.append("passport_number", passportNumber);
    formData.append("place_of_issue", placeOfissue);
    formData.append("expiry_date", formattedPassportExpiryDate);

    // API call to save identification details
    try {
      setLoading(true);

      const response = await createIdentification_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/dashboard");
        setShowConfirmationModal(false);
        toast.success("Identification Details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Identification Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Identification Details:", error);
      toast.error("Failed to save Identification Details");
    }
  };

  const handleNext = async () => {
    // event.preventDefault();

    // const formattedPassportExpiryDate =
    //   passportExpiryDate &&
    //   `${passportExpiryDate.getDate()}/${
    //     passportExpiryDate.getMonth() + 1
    //   }/${passportExpiryDate.getFullYear()}`;

    const formattedPassportExpiryDate = passportExpiryDate
      ? new Date(passportExpiryDate).getDate().toString().padStart(2, "0") +
        "/" +
        (new Date(passportExpiryDate).getMonth() + 1)
          .toString()
          .padStart(2, "0") +
        "/" +
        new Date(passportExpiryDate).getFullYear()
      : "";

    if (countryOfBirth.trim() === "") {
      toast.error("Please select your country of birth.");
      document.getElementById("formBasicSelectCountryofBirth").focus();
      return;
    }

    if (passportCountry.trim() === "") {
      toast.error("Please select your passport country.");
      document.getElementById("formBasicSelectpassportCountry").focus();
      return;
    }

    if (placeOfissue.trim() === "") {
      toast.error("Please enter the place of issue / issuing authority.");
      document.getElementById("fieldplaceOfissueInput").focus();
      return;
    }

    if (passportNumber.trim() === "") {
      toast.error("Please enter your passport number.");
      document.getElementById("passportNumberfieldInput").focus();
      return;
    }

    if (passportExpiryDate === null) {
      toast.error("Please select the passport expiry date.");
      document.getElementById("passportExpirydatefieldInput").focus();
      return;
    }

    // Construct form data object
    const json_data = {};
    // const formData = new FormData();
    json_data.pointer_id = pointerData;
    json_data.country_of_birth = countryOfBirth;
    json_data.passport_country = passportCountry;
    json_data.place_of_birth = "";
    json_data.current_citizenship = "";
    json_data.passport_number = passportNumber;
    json_data.place_of_issue = placeOfissue;
    json_data.expiry_date = formattedPassportExpiryDate;

    // API call to save identification details
    try {
      setLoading(true);


      sessionStorage.setItem("identification_data", JSON.stringify(json_data));
      // 

      navigate("/user/middle_entry/stage_1/usi_and_avetmiss", {
        state: { pointerData },
      });



      // const response = await createIdentification_stage1_API(formData);
      // setLoading(false);

      // if (response?.data?.response?.response === true) {
      //   navigate("/user/middle_entry/stage_1/usi_and_avetmiss", {
      //     state: { pointerData },
      //   });
      //   toast.success("Identification Details saved successfully.");
      // } else {
      //   // If API call fails, display error message
      //   toast.error(
      //     response?.data?.response?.error_msg ||
      //       "Failed to save Identification Details"
      //   );
      // }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Identification Details:", error);
      toast.error("Failed to save Identification Details");
    }
  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    const getIdentificationDetailsFunction = async () => {
      try {
        setLoading(true);

        const response = await getIdentification_stage1_API(
          pointerData
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data
        ) {
          
          const prepare_json = sessionStorage.getItem("identification_data") ?? null;


          const stage_1_identification = (prepare_json) ? JSON.parse(prepare_json) :
            response?.data?.response?.data?.identification_details;

          const originalIncomingString = stage_1_identification?.expiry_date;

          // Split the string into parts
          const parts = originalIncomingString.split("-");

          // Parse the parts into integers
          const year = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const day = parseInt(parts[2]);

          // Create a Date object
          const modifiedDate = new Date(year, month, day);

          if (stage_1_identification?.expiry_date === "" || stage_1_identification?.expiry_date === null || stage_1_identification?.expiry_date === undefined || stage_1_identification?.expiry_date === "undefined") {
            setPassportExpiryDate(null);
          } else {

            var new_expiry = stage_1_identification?.expiry_date;
            if(prepare_json){
              new_expiry = stage_1_identification?.expiry_date.split("/");
              new_expiry = new_expiry[2]+"-"+new_expiry[1]+"-"+new_expiry[0];
            }


            setPassportExpiryDate(new_expiry);
          }

          setCountryOfBirth(stage_1_identification?.country_of_birth);
          setPassportCountry(stage_1_identification?.passport_country);
          setPassportNumber(stage_1_identification?.passport_number);
          setPlaceOfissue(stage_1_identification?.place_of_issue);
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (pointerData) {
      getIdentificationDetailsFunction(pointerData);
    }
  }, [pointerData]);

  return (
    <>
      <div className="forgot-container-identification">
        <Card className="shadow forgot-card-identification">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Identification Details</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                  {localStorage.getItem("portal_reference_no")}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div className="account-details">
                <div className="row mb-3 mt-1">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="formBasicSelectCountryofBirth">
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Country of Birth{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Group controlId="formBasicSelectCountryofBirth">
                      <Form.Select
                        id="formBasicSelectCountryofBirth"
                        value={countryOfBirth}
                        onChange={(e) => setCountryOfBirth(e.target.value)}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="" disabled>
                          Select Country of Birth
                        </option>
                        {/* Map over countryyyyyyyyyyyyyys to generate options */}
                        {countriesData.map((countryyyyyyyyyyyyyy) => (
                          <option
                            key={countryyyyyyyyyyyyyy.id}
                            className="dropdown-options"
                            value={countryyyyyyyyyyyyyy.name}
                          >
                            {countryyyyyyyyyyyyyy.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3 mt-1  bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="formBasicSelectpassportCountry">
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Passport Country{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Group controlId="formBasicSelectpassportCountry">
                      <Form.Select
                        id="formBasicSelectpassportCountry"
                        value={passportCountry}
                        onChange={(e) => setPassportCountry(e.target.value)}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="" disabled selected>
                          Select Passport Country
                        </option>

                        {countriesData.map((countryyyyyyyyyyyyyy) => (
                          <option
                            key={countryyyyyyyyyyyyyy.id}
                            className="dropdown-options"
                            value={countryyyyyyyyyyyyyy.name}
                          >
                            {countryyyyyyyyyyyyyy.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="fieldplaceOfissueLabel">
                      <Form.Label
                        htmlFor="fieldplaceOfissueInput"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Place of Issue / Issuing Authority{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Control
                      id="fieldplaceOfissueInput"
                      type="text"
                      placeholder="Enter Place of Issue / Issuing Authority"
                      value={placeOfissue}
                      onChange={(e) => setPlaceOfissue(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-3  bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="passportNumberfieldlabel">
                      <Form.Label
                        id="passportNumberfieldLabel"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Passport Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Control
                      id="passportNumberfieldInput"
                      type="text"
                      placeholder="Enter Passport Number"
                      value={passportNumber}
                      onChange={(e) => setPassportNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="passportExpirydatefieldInput">
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {/* ▸ Passport Expiry Date (dd/mm/yyyy){" "} */}
                        ▸ Passport Expiry Date{" "}

                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center justify-content-start">
                    <input
                      type="date"
                      id="passportExpirydatefieldInput"
                      value={passportExpiryDate}
                      className="form-control"
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) => setPassportExpiryDate(e.target.value)}
                      onClick={(e) => e.target.showPicker()}
                      style={{ cursor: "pointer", zIndex: "999" }}
                    />

                    {/* 
                    <InputGroup style={{ width: "100%" }}>
                      <DatePicker
                        type='date'
                        id="passportExpirydatefieldInput"
                        selected={passportExpiryDate}
                        onChange={(date) => setPassportExpiryDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Passport expiry date"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        minDate={new Date()}
                        style={{ cursor: "pointer", zIndex: "999" }}
                      />
                      <InputGroup.Text
                        onClick={() =>
                          document
                            .getElementById("passportExpirydatefieldInput")
                            .click()
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <BsCalendar size="0.7em" />
                      </InputGroup.Text>
                    </InputGroup>
                     */}
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 text-center">
                <label
                  htmlFor="inputName4"
                  className="form-label text-center w-75 py-2"
                  style={{
                    backgroundColor: "#055837",
                    color: "#ffc107",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                >
                  Note: A valid current passport is required before the
                  technical interview is conducted
                </label>
              </div>

              {/* {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {loading && <Loader />}

              {/* Back and Save & Exit and Next Buttons */}

              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Back Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleBack}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Back
                  </button>

                  {/* Save & Exit Button */}
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveAndExit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Save & Exit
                  </button> */}

                  {/* Next Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleNext}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Contact Details form page?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default Identification_M;
