import React, { useEffect } from 'react'
import Loader from '../Loader/Loader'
import { encryptData } from '../CRYPTO/crypto';

export default function Access_Granted() {
  const checkingAccessGranted = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // JWT token
    var encryptedToken = "";
    if(searchParams.has('encryptedToken')){
        encryptedToken = encryptData(searchParams.get('encryptedToken'));
    }



    localStorage.setItem("account_type", searchParams.get('account_type'));
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("encryptedToken", encryptedToken);
    localStorage.setItem("userId",  searchParams.get('userId'));
    localStorage.setItem("profileDetailsFilled",  true);
    localStorage.setItem("userName", searchParams.get('userName'));

    window.location = "/user/middle_entry/stage_1/occupation_details?pointer_id="+searchParams.get('pointer_id');
    // END

  }

  useEffect(() => {
    checkingAccessGranted();
  }, []);
  

  return (
    <>
        <Loader />
        <div>Access_Granted</div>
    </>
  )
}
