import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Heading.css";

const Heading_M = ({ text, clientNameInBanner }) => {
  const [candidateName, setCandidateName] = useState("");
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    const updateCandidateName = () => {
      const storedName = localStorage.getItem("active_candidate_name");
      if (storedName) {
        setCandidateName(storedName.trim());
      } else {
        setCandidateName(""); 
      }
    };

    // Initial fetch
    updateCandidateName();

    // Set up the event listener for localStorage changes
    window.addEventListener('storage', updateCandidateName);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', updateCandidateName);
    };

  }, [location.pathname]); // Depend on location.pathname to handle path changes

  // Check if the candidate name should be displayed
  const shouldShowName = candidateName.trim() !== "" && !(
    location.pathname === "/user/incomplete_applications" ||
    location.pathname === "/user/submitted_applications" ||
    location.pathname === "/user/employment_verification_pending" ||
    location.pathname === "/user/employment_verification_pending_details" ||
    location.pathname === "/user/forms" ||
    location.pathname === "/user/complete_profile"
  );

  return (
    <div
      className="bg-green text-white text-center pt-1 pb-1"
      style={{ fontSize: "118%" }}
    >
      <span>
        <b>
          {text} {(clientNameInBanner) ? `(${clientNameInBanner})` : `(${candidateName})`}
        </b>
      </span>
    </div>
  );
};

export default Heading_M;
