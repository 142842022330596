import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Review & Confirm.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getReviewAndConfirm_stage1_API,
  postReviewAndConfirm_stage1_Application_PDF_API,
  getAllOccupationsAPI,
  getAllCountryDataAPI,
  postApplicantDeclaration_Files_by_parameter_stage1_API,
  postReviewAndConfirm_CheckPDFDownloaded_stage1_Application_PDF_API,
  createOccupationDetails_stage1_API,
  createPersonalDetails_stage1_API,
  createContactDetails_stage1_API,
  createIdentification_stage1_API,
  createUSIandAvetmiss_stage1_API,
  createEducationAndEmployment_stage1_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import { resolve } from "path-browserify";

const ReviewAndConfirm_M = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [loading, setLoading] = useState(false);

  const [Parameterrrrr, setParameterrrrr] = useState("");

  const [reviewAndConfirmData, setReviewAndConfirmData] = useState(null);

  // Get All Sessionstorage Data

  const occupation_data = JSON.parse(sessionStorage.getItem("occupation_data")) ?? null;
  const contact_details_data = JSON.parse(sessionStorage.getItem("contact_details_data")) ?? null;
  const education_employment_data = JSON.parse(sessionStorage.getItem("education_employment_data")) ?? null;
  const identification_data = JSON.parse(sessionStorage.getItem("identification_data")) ?? null;
  const personal_details_data = JSON.parse(sessionStorage.getItem("personal_details_data")) ?? null;
  const usi_and_avetmiss_data = JSON.parse(sessionStorage.getItem("usi_and_avetmiss_data")) ?? null;

  // END

  useEffect(() => {
    // Fetch review and confirm data when component mounts
    const fetchReviewAndConfirmData = async (pointerData) => {
      try {
        setLoading(true);

        const response = await getReviewAndConfirm_stage1_API(
          pointerData
        );
        setLoading(false);

        if (response.data?.response && response.data?.response?.data) {
          setReviewAndConfirmData(response?.data?.response?.data);
        } else {
          console.error(
            "Error fetching review and confirm data:",
            response.data?.response?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching review and confirm data:", error);
      }
    };

    if (pointerData) {
      fetchReviewAndConfirmData(pointerData);
    }
  }, [pointerData]);

  const [applicationPDF, setApplicationPDF] = useState(null);

    const fetchPDF = async () => {
      try {
        const formData = new FormData();
        formData.append("pointer_id", pointerData);

        setLoading(true);

        const response = await postReviewAndConfirm_stage1_Application_PDF_API(
          formData
        );
        setLoading(false);
        if (
          response?.data &&
          response?.data?.response?.response === true &&
          response?.data?.response?.data 
          // && response.data.success_msg === "PDF generated successfully."
        ) {
          setApplicationPDF(response?.data?.response?.data?.download_link);
          setParameterrrrr(response?.data?.response?.data?.parameter);

          // Redirect to Submitted Application
          window.location = process.env.REACT_APP_AQATO_AGENT_PORTAL_AGENT_URL_URL+"/admin/application_manager/view_application?pointerID="+pointerData;

          // END
        } else {
          console.error(
            "Error fetching review and confirm PDF:",
            response.data?.error_msg || "Unknown error"
          );
          // toast.error(response.data?.error_msg || "Unknown error");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error in fetching pdf data", error);
        // toast.error("Error in fetching pdf data");
      }
    };


    // Commented for Stage 1 Editor
  //   useEffect(() => {
  //   if (pointerData) {
  //     fetchPDF();
  //   }
  // }, []);

  const checkPDFDownloaded = async () => {
    try {
      const formData = new FormData();
      formData.append("pointer_id", pointerData);
      formData.append("parameter", Parameterrrrr);

      setLoading(true);

      const response =
        await postReviewAndConfirm_CheckPDFDownloaded_stage1_Application_PDF_API(
          formData
        );
      setLoading(false);

      if (
        response?.data &&
        response.status === 200 &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        navigate(
          "/user/middle_entry/stage_1/application_declaration",
          {
            state: { pointerData },
          }
        );
        toast.success("Reviewed and Confirmed successfully done.");
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Error in downloading review and confirm PDF:"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error fetching review and confirm pdf downloaded");
    }
  };

  // useEffect(() => {
  //   if (pointerData) {
  //     checkPDFDownloaded();
  //   }
  // }, [pointerData]);

  const handleNext = async (e) => {
    e.preventDefault();

    // checkPDFDownloaded();

    navigate("/user/middle_entry/stage_1/application_declaration", {
      state: { pointerData },
    });
    toast.success("Reviewed and Confirmed successfully done.");

    // if (!applicationFormPDFDownloaded) {
    //   // Focus on the button element directly
    //   document.getElementById("downloadButton").focus();
    //   toast.error(
    //     "Please download the Application form Preview before proceeding."
    //   );
    // } else {
    //   navigate("/user/middle_entry/stage_1/application_declaration", {
    //     state: { pointerData },
    //   });
    //   toast.success("Reviewed and confirmed successfully.");
    // }
  };

  // Fetch industry experience file when component mounts
  const pdfDownloadedStatusByParameter = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("pointer_id", pointerData);
      formData.append("parameter", Parameterrrrr);

      const response =
        await postApplicantDeclaration_Files_by_parameter_stage1_API(formData);

      setLoading(false);

      if (
        response?.data &&
        response.status === 200 &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        const downloadLink = document.createElement("a");
        downloadLink.href = applicationPDF;
        downloadLink.setAttribute("download", "application preview.pdf");
        downloadLink.setAttribute("target", "_blank");

        downloadLink.click();

        setApplicationFormPDFDownloaded(true);
        toast.success(
          "Please Download Application Form Preview that is opened."
        );
      } else {
        console.error(
          "Error fetching Application Preview pdf file:",
          response.data?.error_msg || "Unknown error"
        );

        toast.error(response.data?.error_msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const [applicationFormPDFDownloaded, setApplicationFormPDFDownloaded] =
    useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/middle_entry/stage_1/education_employment", {
      state: { pointerData },
    });
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Education & Employment form page.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/middle_entry/stage_1/education_employment", {
      state: { pointerData },
    });
    // toast.success("Navigated to Education & Employment form page.");
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = async () => {
    // Set Loading True
    handleConfirmationNo();
    setLoading(true);
    // Make a code to call all the apis until saving is finish when finish back to admin page
    const occupation_data = JSON.parse(sessionStorage.getItem("occupation_data")) ?? null;
    const personal_details_data = JSON.parse(sessionStorage.getItem("personal_details_data")) ?? null;
    const contact_details_data = JSON.parse(sessionStorage.getItem("contact_details_data")) ?? null;
    const identification_data = JSON.parse(sessionStorage.getItem("identification_data")) ?? null;
    const usi_and_avetmiss_data = JSON.parse(sessionStorage.getItem("usi_and_avetmiss_data")) ?? null;
    const education_employment_data = JSON.parse(sessionStorage.getItem("education_employment_data")) ?? null;
    // END

    await saveTHEALLFORMS(occupation_data, "createOccupationDetails_stage1_API");
    await saveTHEALLFORMS(personal_details_data, "createPersonalDetails_stage1_API");
    await saveTHEALLFORMS(contact_details_data, "createContactDetails_stage1_API");
    await saveTHEALLFORMS(identification_data, "createIdentification_stage1_API");
    await saveTHEALLFORMS(usi_and_avetmiss_data, "createUSIandAvetmiss_stage1_API");
    await saveTHEALLFORMS(education_employment_data, "createEducationAndEmployment_stage1_API");


    fetchPDF();



    // setLoading(false);
    // END


    // navigate("/user/dashboard");
    // setShowConfirmationModal(false);
    // toast.success("Reviewed and Confirmed successfully done.");




  };

  const saveTHEALLFORMS = async (form_datas, function_name) => {

    var final_formData = new FormData();
    console.log(form_datas);
    for(const form_data in form_datas){
      // console.log(form_data, " ", form_datas[form_data]);
      final_formData.append(form_data, form_datas[form_data]);
    }

    switch(function_name){
      case 'createOccupationDetails_stage1_API': 
        await createOccupationDetails_stage1_API(final_formData)
        break;

      case 'createPersonalDetails_stage1_API':
        await createPersonalDetails_stage1_API(final_formData);
        break;

      case 'createContactDetails_stage1_API':
        await createContactDetails_stage1_API(final_formData);
        break;

      case 'createIdentification_stage1_API':
        await createIdentification_stage1_API(final_formData);
        break;

      case 'createUSIandAvetmiss_stage1_API':
        await createUSIandAvetmiss_stage1_API(final_formData);
        break;

      case 'createEducationAndEmployment_stage1_API':
        await createEducationAndEmployment_stage1_API(final_formData);
        break;

    }
  }

  const handleApplicationFormDownloaded = () => {
    if (applicationPDF) {
      pdfDownloadedStatusByParameter();

      // const downloadLink = document.createElement("a");
      // downloadLink.href = applicationPDF;
      // downloadLink.setAttribute("download", "application preview.pdf");
      // downloadLink.setAttribute("target", "_blank");

      // downloadLink.click();

      // setApplicationFormPDFDownloaded(true);
      // toast.success("Please Download Application Form Preview that is opened.");
    } else {
      toast.error(
        "Failed to download Application Form Preview. Link not available."
      );
    }
  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  const [occupations, setOccupations] = useState([]);

  useEffect(() => {
    // Fetch occupations when component mounts
    const fetchOccupations = async () => {
      try {
        const response = await getAllOccupationsAPI();
        if (response.data?.response && response.data?.response?.data) {
          setOccupations(response.data.response.data);
        } else {
          console.error(
            "Error fetching occupations:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching occupations:", error);
      }
    };

    fetchOccupations();
  }, []);

  const [occupationName, setOccupationName] = useState("");

  useEffect(() => {
    // Map method to find and print the name of the occupation
    const findOccupationName = () => {
      const selectedOccupation = occupations.find(
        (item) => item.id === occupation_data?.occupation_id
      );
      if (selectedOccupation) {
        setOccupationName(selectedOccupation?.name);
      } else {
        console.log("Occupation not found.");
      }
    };

    // Call the method
    findOccupationName();
  }, [reviewAndConfirmData, occupations]);

  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      try {
        const response = await getAllCountryDataAPI();
        if (response.data?.response && response.data?.response?.data) {
          setCountriesData(response.data.response.data);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const [residentialCountryName, setResidentialCountryName] = useState("");
  const [postalCountryName, setPostalCountryName] = useState("");

  const [countryOfBirth, setCountryOfBirth] = useState("");

  const [passportCountry, setPassportCountry] = useState("");

  useEffect(() => {
    // Map method to find and set the name of the country
    const findCountryName = () => {
      const selectedCountry = contact_details_data?.country;

      const selectedPostalCountry =
        contact_details_data?.Postal_country;

      const countryOfBirthByAPI =
        reviewAndConfirmData?.identification_details?.country_of_birth;

      const passportCountryByAPI =
        reviewAndConfirmData?.identification_details?.passport_country;

      if (
        selectedCountry ||
        selectedPostalCountry ||
        countryOfBirthByAPI ||
        passportCountryByAPI
      ) {
        setResidentialCountryName(selectedCountry);
        setPostalCountryName(selectedPostalCountry);
        setCountryOfBirth(countryOfBirthByAPI);
        setPassportCountry(passportCountryByAPI);
      } else {
        console.log("Country not found.");
      }
    };

    // Call the method
    findCountryName();
  }, [reviewAndConfirmData, countriesData]);


  const resolvePhoneCodeProblem = (countryCode) => {
    var data = "";
    countriesData.forEach(country => {
      if(countryCode == country?.id){
        // console.log(country?.phonecode, "country?.phonecode");
        data = country?.phonecode
      }
      // console.log("country", country);
    });
    return data;
  }
  
  const formatDate = (dateString) => {
    if (!dateString) return "";

    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatDateForTitle = (dateString) => {
    if (!dateString) return "";

    const [datePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year}`;
  };

  const formatWithoutUnderScoreStatus = (status) => {
    if (!status) return "";
    return status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <>
      <div className="forgot-container-new-Review">
        <Card className="shadow forgot-card-new-Review ">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Review & Confirm</b>
          </div>
          <Card.Body>
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div
                    className="d-inline-block"
                    style={{ marginBottom: "10px" }}
                  >
                    Date:{" "}
                    {formatDateForTitle(
                      reviewAndConfirmData?.stage_1_pdf_download?.update_date
                    )}
                  </div>
                  <br></br>
                  <div className="d-inline-block">
                    Applicant Name:{" "}
                    {personal_details_data?.preferred_title}{" "}
                    {
                      personal_details_data
                        ?.first_or_given_name
                    }{" "}
                    {personal_details_data?.middle_names}{" "}
                    {
                      personal_details_data
                        ?.surname_family_name
                    }
                  </div>
                </div>
                <div className="col-auto">
                  {(reviewAndConfirmData?.photo === "") ? (



                  <div
                    className="profile_image float-end"
                    style={{
                      width: "150px",
                      height: "180px",
                      border: "1px solid black",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <p className="text-center">Passport photo</p>
                  </div>




                  ) : (
                    <img src={reviewAndConfirmData?.photo} style={{
                      float: "right",
                      width: "150px",
                      height: "180px",
                      border: "1px solid black",
                      borderRadius: "5px",
                      objectFit: "cover"
                    }}/>
                  )}
                  
                  {/* <div
                    className="profile_image float-end"
                    style={{
                      width: "150px",
                      height: "180px",
                      border: "1px solid black",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <p className="text-center">Passport photo</p>
                  </div> */}

                </div>
              </div>
            </div>

            <Form>
              <div className="account-details ">
                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Occupation Details</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {occupationName !== "" && (
                                <tr>
                                  <td className="col-md-6">Occupation</td>

                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>

                                  <td className="col-md-6">{occupationName}</td>
                                </tr>
                              )}

                              {occupation_data?.program !==
                                "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Skills Assessment Program
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {occupation_data?.program}
                                  </td>
                                </tr>
                              )}

                              {occupation_data?.pathway !==
                                "" && (
                                <tr>
                                  <td className="col-md-6">Pathway</td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {occupation_data?.pathway}{" "}
                                  </td>
                                </tr>
                              )}

                              {reviewAndConfirmData?.occupation
                                ?.currently_in_australia !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Are you currently in Australia ?
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {reviewAndConfirmData?.occupation
                                      ?.currently_in_australia
                                      ? occupation_data?.currently_in_australia
                                          .charAt(0)
                                          .toUpperCase() +
                                        occupation_data?.currently_in_australia.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {reviewAndConfirmData?.occupation
                                ?.conjunction_with_skills_assessment !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Which visa do you intend to apply for in
                                    conjunction with this skills assessment ?
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      reviewAndConfirmData?.occupation
                                        ?.conjunction_with_skills_assessment
                                    }
                                  </td>
                                </tr>
                              )}

                              {reviewAndConfirmData?.occupation
                                ?.currently_on_bridging_visa !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Are you currently on a bridging Visa ?
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {reviewAndConfirmData?.occupation
                                      ?.currently_on_bridging_visa
                                      ? occupation_data?.currently_on_bridging_visa
                                          .charAt(0)
                                          .toUpperCase() +
                                        occupation_data?.currently_on_bridging_visa.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {reviewAndConfirmData?.occupation
                                ?.current_visa_category !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Current Visa Category & Subclass
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      reviewAndConfirmData?.occupation
                                        ?.current_visa_category
                                    }
                                  </td>
                                </tr>
                              )}

                              {occupation_data?.visa_expiry !==
                                "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Visa Expiry Date (dd/mm/yyyy)
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {/* {formatDate(
                                      reviewAndConfirmData?.occupation
                                        ?.visa_expiry
                                    )} */}

                                    {
                                      reviewAndConfirmData?.occupation
                                        ?.visa_expiry
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Personal Details (As per Passport)</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {personal_details_data
                                ?.preferred_title !== "" && (
                                <tr>
                                  <td className="col-md-6">Preferred Title</td>

                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>

                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.preferred_title
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.surname_family_name !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Surname / Family Name
                                  </td>

                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>

                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.surname_family_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.first_or_given_name !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    First or given Name
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.first_or_given_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.middle_names !== "" && (
                                <tr>
                                  <td className="col-md-6">Middle Name</td>

                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>

                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.middle_names
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.known_by_any_name !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Are you known by any other Name ?
                                  </td>

                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>

                                  <td className="col-md-6">
                                    {personal_details_data
                                      ?.known_by_any_name
                                      ? personal_details_data?.known_by_any_name
                                          .charAt(0)
                                          .toUpperCase() +
                                        personal_details_data?.known_by_any_name.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.previous_surname_or_family_name !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Previous Surname / Family Name
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.previous_surname_or_family_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.previous_names !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Previous First or Given Name
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.previous_names
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.previous_middle_names !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Previous Middle Name
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.previous_middle_names
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.gender !== "" && (
                                <tr>
                                  <td className="col-md-6">Gender</td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {
                                      personal_details_data
                                        ?.gender
                                    }
                                  </td>
                                </tr>
                              )}

                              {personal_details_data
                                ?.date_of_birth !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Date of Birth (dd/mm/yyyy)
                                  </td>
                                  <td className="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td className="col-md-6">
                                    {/* {formatDate(
                                      personal_details_data
                                        ?.date_of_birth
                                    )} */}

                                    {
                                      personal_details_data
                                        ?.date_of_birth
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Contact Details</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {contact_details_data?.email !==
                                "" && (
                                <tr>
                                  <td className="col-md-6">Email</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.email
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.alternate_email !== "" && (
                                <tr>
                                  <td className="col-md-6">Alternate Email</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.alternate_email
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.mobile_number_code !== "" &&
                                contact_details_data
                                  ?.mobile_number !== "" && (
                                  <tr>
                                    <td className="col-md-6">Mobile Number</td>
                                    <td className="col-md-1">:</td>
                                    <td className="col-md-5">
                                      +
                                      {
                                        resolvePhoneCodeProblem(contact_details_data
                                          ?.mobile_number_code)
                                      }{" "}
                                      {
                                        contact_details_data
                                          ?.mobile_number
                                      }
                                    </td>
                                  </tr>
                                )}

                              {contact_details_data
                                ?.alter_mobile !== "" &&
                                contact_details_data
                                  ?.alter_mobile_code !== "" && (
                                  <tr>
                                    <td className="col-md-6">
                                      Alternate Mobile Number
                                    </td>
                                    <td className="col-md-1">:</td>
                                    <td className="col-md-5">
                                      +
                                      {
                                        resolvePhoneCodeProblem(contact_details_data
                                          ?.alter_mobile_code)
                                      }{" "}
                                      {
                                        contact_details_data
                                          ?.alter_mobile
                                      }
                                    </td>
                                  </tr>
                                )}

                              <tr>
                                <td className="col-md-6" colSpan="3">
                                  <h5>
                                    <b>Residential Address</b>
                                  </h5>
                                </td>
                              </tr>

                              {contact_details_data
                                ?.unit_flat_number !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Unit / Flat Number
                                  </td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.unit_flat_number
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.street_lot_number !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Street / Lot Number
                                  </td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.street_lot_number
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.street_name !== "" && (
                                <tr>
                                  <td className="col-md-6">Street Name</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.street_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data?.suburb !==
                                "" && (
                                <tr>
                                  <td className="col-md-6">Suburb / City</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.suburb
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.state_proviance !== "" && (
                                <tr>
                                  <td className="col-md-6">State / Province</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.state_proviance
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.postcode !== "" && (
                                <tr>
                                  <td className="col-md-6">Postcode</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.postcode
                                    }
                                  </td>
                                </tr>
                              )}

                              {residentialCountryName !== "" && (
                                <tr>
                                  <td className="col-md-6">Country</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {residentialCountryName}
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.postal_address_is_different !== "" && (
                                <tr>
                                  <td className="col-md-6">
                                    Postal Address is same as Residential
                                    Address
                                  </td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {contact_details_data
                                      ?.postal_address_is_different
                                      ? contact_details_data?.postal_address_is_different
                                          .charAt(0)
                                          .toUpperCase() +
                                        contact_details_data?.postal_address_is_different.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.postal_address_is_different === "no" && (
                                <>
                                  {postalCountryName !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        {" "}
                                        Postal Country
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {postalCountryName}
                                      </td>
                                    </tr>
                                  )}

                                  {contact_details_data
                                    ?.postal_unit_flat_number !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal Unit / Flat Number
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {
                                          contact_details_data
                                            ?.postal_unit_flat_number
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {contact_details_data
                                    ?.postal_street_lot_number !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal Street / Lot Number
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {
                                          contact_details_data
                                            ?.postal_street_lot_number
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {contact_details_data
                                    ?.postal_street_name !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal Street Name
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {
                                          contact_details_data
                                            ?.postal_street_name
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {contact_details_data
                                    ?.postal_suburb !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal Suburb / City
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {
                                          contact_details_data
                                            ?.postal_suburb
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {contact_details_data
                                    ?.postal_state_proviance !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal State / Province
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {
                                          contact_details_data
                                            ?.postal_state_proviance
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {contact_details_data
                                    ?.postal_postcode !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal Postcode
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {
                                          contact_details_data
                                            ?.postal_postcode
                                        }
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}

                              <tr>
                                <td className="col-md-6" colSpan="3">
                                  <h5>
                                    <b>Emergency Contact Details</b>
                                  </h5>
                                </td>
                              </tr>

                              {contact_details_data
                                ?.first_name !== "" && (
                                <tr>
                                  <td className="col-md-6">First Name</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.first_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.surname !== "" && (
                                <tr>
                                  <td className="col-md-6">Surname</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.surname
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.relationship !== "" && (
                                <tr>
                                  <td className="col-md-6">Relationship</td>
                                  <td className="col-md-1">:</td>
                                  <td className="col-md-5">
                                    {
                                      contact_details_data
                                        ?.relationship
                                    }
                                  </td>
                                </tr>
                              )}

                              {contact_details_data
                                ?.emergency_mobile_code !== "" &&
                                contact_details_data
                                  ?.emergency_mobile !== "" && (
                                  <tr>
                                    <td className="col-md-6">Mobile Number</td>
                                    <td className="col-md-1">:</td>
                                    <td className="col-md-5">
                                      +
                                      {
                                        resolvePhoneCodeProblem(contact_details_data
                                          ?.emergency_mobile_code)
                                      }{" "}
                                      {
                                        contact_details_data
                                          ?.emergency_mobile
                                      }
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Identification Details</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {countryOfBirth !== "" && (
                                <tr>
                                  <td class="col-md-6"> Country of Birth</td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">{identification_data?.country_of_birth}</td>
                                </tr>
                              )}

                              {passportCountry !== "" && (
                                <tr>
                                  <td class="col-md-6">Passport Country</td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">{identification_data?.passport_country}</td>
                                </tr>
                              )}

                              {identification_data?.place_of_issue !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Place of Issue / Issuing Authority
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {
                                      identification_data?.place_of_issue
                                    }
                                  </td>
                                </tr>
                              )}

                              {identification_data?.passport_number !== "" && (
                                <tr>
                                  <td class="col-md-6">Passport Number</td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {
                                      identification_data?.passport_number
                                    }
                                  </td>
                                </tr>
                              )}

                              {identification_data?.expiry_date !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Passport Expiry Date (dd/mm/yyyy)
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {/* {formatDate(
                                      reviewAndConfirmData
                                        ?.identification_details?.expiry_date
                                    )} */}

                                    {
                                      identification_data?.expiry_date
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Unique Student Identifier (USI)</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {usi_and_avetmiss_data?.currently_have_usi === "yes" && (
                                <>
                                  {usi_and_avetmiss_data    ?.currently_have_usi !== "" && (
                                    <tr>
                                      <td class="col-md-6">
                                        I am currently in Australia?
                                      </td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {usi_and_avetmiss_data          ?.currently_have_usi
                                          ? usi_and_avetmiss_data?.currently_have_usi
                                              .charAt(0)
                                              .toUpperCase() +
                                            usi_and_avetmiss_data?.currently_have_usi.slice(
                                              1
                                            )
                                          : ""}
                                      </td>
                                    </tr>
                                  )}

                                  {usi_and_avetmiss_data?.usi_no !==
                                    "" && (
                                    <tr>
                                      <td class="col-md-6">USI</td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          usi_and_avetmiss_data?.usi_no
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {usi_and_avetmiss_data?.have_usi_transcript !== "" && (
                                    <tr>
                                      <td class="col-md-6">
                                        Do you have a USI Transcript?
                                      </td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {usi_and_avetmiss_data?.have_usi_transcript
                                          ? usi_and_avetmiss_data?.have_usi_transcript
                                              .charAt(0)
                                              .toUpperCase() +
                                            usi_and_avetmiss_data?.have_usi_transcript.slice(
                                              1
                                            )
                                          : ""}
                                      </td>
                                    </tr>
                                  )}

                                  {usi_and_avetmiss_data?.permission_access_usi_transcripts !==
                                    "" && (
                                    <tr>
                                      <td class="col-md-6">
                                        I have assigned ATTC permission to
                                        access USI Transcripts.
                                      </td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {usi_and_avetmiss_data?.permission_access_usi_transcripts
                                          ? usi_and_avetmiss_data?.permission_access_usi_transcripts
                                              .charAt(0)
                                              .toUpperCase() +
                                            usi_and_avetmiss_data?.permission_access_usi_transcripts.slice(
                                              1
                                            )
                                          : ""}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}

                              {usi_and_avetmiss_data?.currently_have_usi === "no" && (
                                <tr>
                                  <td class="col-md-6">
                                    I am offshore (outside of Australia) - Do
                                    not need any USI ?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data?.currently_have_usi === "no"
                                      ? "Yes"
                                      : ""}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Marketing</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {reviewAndConfirmData?.user_details?.marketing !==
                                "" && (
                                <tr>
                                  <td class="col-md-6">
                                    I give Australian Trade Training College
                                    permission to use photos/ images in public
                                    material and social media (including any
                                    photos/ images where I may be recognised or
                                    participating in workplace activities) for
                                    current and future marketing and business
                                    purposes. I understand that I retain the
                                    right to withdraw my consent at any time via
                                    email to tra@attc.org.au.
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data      ?.marketing
                                      ? usi_and_avetmiss_data?.marketing
                                          .charAt(0)
                                          .toUpperCase() +
                                        usi_and_avetmiss_data?.marketing.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                {reviewAndConfirmData?.user_details?.account_type ===
                  "Agent" && (
                  <>
                    <div style={{ marginTop: "25px" }}>
                      <Card className="shadow">
                        <div
                          className="card-header text-center"
                          style={{ fontSize: "20px", color: "#055837" }}
                        >
                          <b>Representative Details</b>
                        </div>
                        <Card.Body>
                          <Form>
                            <div className="d-flex justify-content-center">
                              <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                                <tbody>
                                  {reviewAndConfirmData?.user_details?.name !==
                                    "" && (
                                    <tr>
                                      <td class="col-md-6">
                                        Name of Agent or Representative
                                      </td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.name
                                        }{" "}
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.middle_name
                                        }{" "}
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.last_name
                                        }{" "}
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.business_name !== "" && (
                                    <tr>
                                      <td class="col-md-6">
                                        Company Name (if applicable)
                                      </td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.business_name
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details?.email !==
                                    "" && (
                                    <tr>
                                      <td class="col-md-6">E-mail</td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.email
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.mobile_code !== "" &&
                                    reviewAndConfirmData?.user_details
                                      ?.mobile_no !== "" && (
                                      <tr>
                                        <td class="col-md-6">Mobile</td>
                                        <td class="col-md-1">
                                          <span>:</span>
                                        </td>
                                        <td class="col-md-5">
                                          +
                                          {
                                            reviewAndConfirmData?.user_details
                                              ?.mobile_code
                                          }{" "}
                                          {
                                            reviewAndConfirmData?.user_details
                                              ?.mobile_no
                                          }
                                        </td>
                                      </tr>
                                    )}

                                  {reviewAndConfirmData?.user_details
                                    ?.tel_code !== "" &&
                                    reviewAndConfirmData?.user_details
                                      ?.tel_no !== "" && (
                                      <tr>
                                        <td class="col-md-6">Telephone</td>
                                        <td class="col-md-1">
                                          <span>:</span>
                                        </td>
                                        <td class="col-md-5">
                                          {" "}
                                          +
                                          {
                                            reviewAndConfirmData?.user_details
                                              ?.tel_code
                                          }{" "}
                                          {
                                            reviewAndConfirmData?.user_details
                                              ?.tel_no
                                          }
                                        </td>
                                      </tr>
                                    )}

                                  {reviewAndConfirmData?.user_details
                                    ?.unit_flat !== "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Unit / Flat Number
                                      </td>
                                      <td className="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td className="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.unit_flat
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.street_lot !== "" && (
                                    <tr>
                                      <td class="col-md-6">
                                        Street / Lot Number
                                      </td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.street_lot
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.street_name !== "" && (
                                    <tr>
                                      <td class="col-md-6">Street Name</td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.street_name
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.suburb_city !== "" && (
                                    <tr>
                                      <td class="col-md-6">Suburb / City</td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.suburb_city
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.state_province !== "" && (
                                    <tr>
                                      <td class="col-md-6">State / Province</td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.state_province
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.postcode !== "" && (
                                    <tr>
                                      <td class="col-md-6">Postcode</td>
                                      <td class="col-md-1">
                                        <span>:</span>
                                      </td>
                                      <td class="col-md-5">
                                        {
                                          reviewAndConfirmData?.user_details
                                            ?.postcode
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.postal_ad_same_physical_ad_check !==
                                    "" && (
                                    <tr>
                                      <td className="col-md-6">
                                        Postal Address Same As Physical Address
                                      </td>
                                      <td className="col-md-1">:</td>
                                      <td className="col-md-5">
                                        {reviewAndConfirmData?.user_details
                                          ?.postal_ad_same_physical_ad_check ===
                                        "1"
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    </tr>
                                  )}

                                  {reviewAndConfirmData?.user_details
                                    ?.postal_ad_same_physical_ad_check ===
                                    "0" && (
                                    <>
                                      {reviewAndConfirmData?.user_details
                                        ?.postal_unit_flat !== "" && (
                                        <tr>
                                          <td class="col-md-6">
                                            Postal Unit / Flat Number
                                          </td>
                                          <td class="col-md-1">
                                            <span>:</span>
                                          </td>
                                          <td class="col-md-5">
                                            {
                                              reviewAndConfirmData?.user_details
                                                ?.postal_unit_flat
                                            }
                                          </td>
                                        </tr>
                                      )}

                                      {reviewAndConfirmData?.user_details
                                        ?.postal_street_lot !== "" && (
                                        <tr>
                                          <td class="col-md-6">
                                            Postal Street / Lot Number
                                          </td>
                                          <td class="col-md-1">
                                            <span>:</span>
                                          </td>
                                          <td class="col-md-5">
                                            {
                                              reviewAndConfirmData?.user_details
                                                ?.postal_street_lot
                                            }
                                          </td>
                                        </tr>
                                      )}

                                      {reviewAndConfirmData?.user_details
                                        ?.postal_street_name !== "" && (
                                        <tr>
                                          <td class="col-md-6">
                                            Postal Street Name
                                          </td>
                                          <td class="col-md-1">
                                            <span>:</span>
                                          </td>
                                          <td class="col-md-5">
                                            {
                                              reviewAndConfirmData?.user_details
                                                ?.postal_street_name
                                            }
                                          </td>
                                        </tr>
                                      )}

                                      {reviewAndConfirmData?.user_details
                                        ?.postal_suburb_city !== "" && (
                                        <tr>
                                          <td class="col-md-6">
                                            Postal Suburb / City
                                          </td>
                                          <td class="col-md-1">
                                            <span>:</span>
                                          </td>
                                          <td class="col-md-5">
                                            {
                                              reviewAndConfirmData?.user_details
                                                ?.postal_suburb_city
                                            }
                                          </td>
                                        </tr>
                                      )}

                                      {reviewAndConfirmData?.user_details
                                        ?.postal_suburb_city !== "" && (
                                        <tr>
                                          <td class="col-md-6">
                                            Postal State / Province
                                          </td>
                                          <td class="col-md-1">
                                            <span>:</span>
                                          </td>
                                          <td class="col-md-5">
                                            {
                                              reviewAndConfirmData?.user_details
                                                ?.postal_state_province
                                            }
                                          </td>
                                        </tr>
                                      )}

                                      {reviewAndConfirmData?.user_details
                                        ?.postal_postcode !== "" && (
                                        <tr>
                                          <td class="col-md-6">
                                            Postal Postcode
                                          </td>
                                          <td class="col-md-1">
                                            <span>:</span>
                                          </td>
                                          <td class="col-md-5">
                                            {
                                              reviewAndConfirmData?.user_details
                                                ?.postal_postcode
                                            }
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Form>
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                )}

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Avetmiss Details</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {countryOfBirth !== "" && (
                                <tr>
                                  <td class="col-md-6">Country of birth</td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">{identification_data?.country_of_birth}</td>
                                </tr>
                              )}

                              {usi_and_avetmiss_data?.speak_english_at_home !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Do you speak a language other than English
                                    at home?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data      ?.speak_english_at_home
                                      ? usi_and_avetmiss_data?.speak_english_at_home
                                          .charAt(0)
                                          .toUpperCase() +
                                        usi_and_avetmiss_data?.speak_english_at_home.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {usi_and_avetmiss_data?.specify_language !== "" &&
                                usi_and_avetmiss_data  ?.speak_english_at_home === "yes" && (
                                  <tr>
                                    <td className="col-md-6">
                                      Specify Language
                                    </td>
                                    <td className="col-md-1">
                                      <span>:</span>
                                    </td>
                                    <td className="col-md-5">
                                      {
                                        usi_and_avetmiss_data          ?.specify_language
                                      }
                                    </td>
                                  </tr>
                                )}

                              {usi_and_avetmiss_data?.proficiency_in_english !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Proficiency in Spoken English
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data      ?.proficiency_in_english
                                      ? usi_and_avetmiss_data?.proficiency_in_english
                                          .charAt(0)
                                          .toUpperCase() +
                                        usi_and_avetmiss_data?.proficiency_in_english.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {usi_and_avetmiss_data?.are_you_aboriginal !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Are you of Aboriginal or Torres Strait
                                    Islander Origin?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data      ?.are_you_aboriginal
                                      ? usi_and_avetmiss_data?.are_you_aboriginal
                                          .charAt(0)
                                          .toUpperCase() +
                                        usi_and_avetmiss_data?.are_you_aboriginal.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {usi_and_avetmiss_data?.choose_origin !== "" &&
                                usi_and_avetmiss_data  ?.are_you_aboriginal === "yes" && (
                                  <tr>
                                    <td className="col-md-6">Origin Option</td>
                                    <td className="col-md-1">
                                      <span>:</span>
                                    </td>
                                    <td className="col-md-5">
                                      {
                                        usi_and_avetmiss_data          ?.choose_origin
                                      }
                                    </td>
                                  </tr>
                                )}

                              {usi_and_avetmiss_data?.have_any_disability !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Do you consider yourself to have a
                                    disability, impairment or long-term
                                    condition?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data      ?.have_any_disability
                                      ? usi_and_avetmiss_data?.have_any_disability
                                          .charAt(0)
                                          .toUpperCase() +
                                        usi_and_avetmiss_data?.have_any_disability.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {usi_and_avetmiss_data?.indicate_area !== "" &&
                                usi_and_avetmiss_data  ?.have_any_disability === "yes" && (
                                  <tr>
                                    <td className="col-md-6">
                                      Indicate disability area
                                    </td>
                                    <td className="col-md-1">
                                      <span>:</span>
                                    </td>
                                    <td className="col-md-5">
                                      {
                                        usi_and_avetmiss_data          ?.indicate_area
                                      }
                                    </td>
                                  </tr>
                                )}

                              {usi_and_avetmiss_data?.require_additional_support !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Will you require additional support to
                                    participate in this Skills Assessment?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {usi_and_avetmiss_data      ?.require_additional_support
                                      ? usi_and_avetmiss_data?.require_additional_support
                                          .charAt(0)
                                          .toUpperCase() +
                                        usi_and_avetmiss_data?.require_additional_support.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {usi_and_avetmiss_data?.note !== "" &&
                                usi_and_avetmiss_data  ?.require_additional_support === "yes" && (
                                  <tr>
                                    <td className="col-md-6">
                                      Please specify the support you need
                                    </td>
                                    <td className="col-md-1">
                                      <span>:</span>
                                    </td>
                                    <td className="col-md-5">
                                      {usi_and_avetmiss_data?.note}
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Education Details</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {education_employment_data?.highest_completed_school_level !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    {" "}
                                    What is your highest COMPLETED school level
                                    ?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {
                                      education_employment_data
                                        ?.highest_completed_school_level
                                    }
                                  </td>
                                </tr>
                              )}

                              {education_employment_data?.still_enrolled_in_secondary_or_senior_secondary_education !==
                                "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Are you still enrolled in secondary or
                                    senior secondary education ?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {education_employment_data
                                      ?.still_enrolled_in_secondary_or_senior_secondary_education
                                      ? education_employment_data?.still_enrolled_in_secondary_or_senior_secondary_education
                                          .charAt(0)
                                          .toUpperCase() +
                                        education_employment_data?.still_enrolled_in_secondary_or_senior_secondary_education.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {education_employment_data?.completed_any_qualifications !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    Have you SUCCESSFULLY completed any
                                    qualifications ?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {education_employment_data
                                      ?.completed_any_qualifications
                                      ? education_employment_data?.completed_any_qualifications
                                          .charAt(0)
                                          .toUpperCase() +
                                          education_employment_data?.completed_any_qualifications.slice(
                                          1
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}

                              {education_employment_data?.applicable_qualifications !== "" &&
                                education_employment_data  ?.completed_any_qualifications === "yes" && (
                                  <tr>
                                    <td className="col-md-6">
                                      Applicable Qualifications
                                    </td>
                                    <td className="col-md-1">
                                      <span>:</span>
                                    </td>
                                    <td className="col-md-5">
                                      {education_employment_data
                                        ?.applicable_qualifications
                                        ? education_employment_data?.applicable_qualifications
                                            .charAt(0)
                                            .toUpperCase() +
                                            education_employment_data?.applicable_qualifications.slice(
                                            1
                                          ).replaceAll("_"," ")
                                        : ""}
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Employment Details</b>
                    </div>
                    <Card.Body>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <table className="table table-borderless table-hover mb-0 w-80 table-striped print-friendly">
                            <tbody>
                              {education_employment_data?.current_employment_status !== "" && (
                                <tr>
                                  <td class="col-md-6">
                                    What is your current employment status ?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {formatWithoutUnderScoreStatus(
                                      education_employment_data
                                        ?.current_employment_status
                                    )}
                                  </td>
                                </tr>
                              )}

                              {education_employment_data?.reason_for_undertaking_this_skills_assessment !==
                                "" && (
                                <tr>
                                  <td class="col-md-6">
                                    What BEST describes your main reason for
                                    undertaking this skills assessment ?
                                  </td>
                                  <td class="col-md-1">
                                    <span>:</span>
                                  </td>
                                  <td class="col-md-5">
                                    {formatWithoutUnderScoreStatus(
                                      education_employment_data
                                        ?.reason_for_undertaking_this_skills_assessment
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <div></div>

                  <button
                    id="downloadButton"
                    type="button"
                    className="btn btn-primary"
                    onClick={handleApplicationFormDownloaded}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    <i className="bi bi-download me-2"></i> Download Application
                    Preview
                  </button>
                </div> */}
              </div>
              {/* 
              {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {loading && <Loader />}

              {/* Back and Save & Exit and Next Buttons */}
              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Back Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleBack}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveAndExit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Save & Exit
                  </button>

                  {/* Next Button */}
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleNext}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Next
                  </button> */}
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm"
            message="Do you want to update the TRA Application Form ?"
          />

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Education & Employment form page?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default ReviewAndConfirm_M;
