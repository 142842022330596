import React, { useEffect } from "react";
import axios from "axios";
import { decryptData } from "./components/CRYPTO/crypto";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

// Create Axios instance with Authorization
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AQATO_AGENT_APPLICANT_PORTAL_BASE_API_URL,
  timeout: 30000,
});

// Create Axios instance without Authorization
const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_AQATO_AGENT_APPLICANT_PORTAL_BASE_API_URL,
  timeout: 30000,
});









function handleTokenExpiration() {
  // console.log("Session expired. Logging out the user...");

  localStorage.clear();
  window.location.href = "/";
  toast.success("Your session has expired. Please log in again to continue.");
}


// Function to get the decrypted token and set Authorization header
export async function authorizeMe(config) {
  const encryptedToken = localStorage.getItem("encryptedToken") || "";
  const token = encryptedToken ? decryptData(encryptedToken) : "";

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete config.headers["Authorization"];
  }
}


// Response Interceptor for token handling and retry logic
axiosInstance.interceptors.response.use(
  async (response) => {
    // console.log(response, "rehkgkehghsekghrjegh36522jkr");

    // Handle specific responses like "Missing JWT token"
    if (response.status === 200 && response.data === "Missing JWT token") {
      await authorizeMe(response);

      const originalRequest = response.config;
      originalRequest.headers["Authorization"] =
        axiosInstance.defaults.headers.common["Authorization"];

      return axiosInstance(originalRequest);
    }
    return response;
  },
  (error) => {
    // console.log(error, "errormakhanjeharh");

    if (error?.response?.data?.message === "Expired token") {
      handleTokenExpiration();
    }
    return Promise.reject(error);
  }
);

// Request Interceptor for adding the Authorization token to each request
axiosInstance.interceptors.request.use(
  async (config) => {
    await authorizeMe(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);














export async function LoginAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/user_login_check",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function forgotPasswordAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/account_update_pass_reset",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createNewAccountAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/create_an_account",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateProfileAPI(data) {
  try {
    const response = await axiosInstance.post("/user/account_update_", data);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function updatePasswordAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/update_new_password",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateOldToNewPasswordAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/old_to_new_password_update",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function dashboardDataAPI() {
  try {
    const response = await axiosInstance.get("/user/get_dashboard_data_api");

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getUserDataAPI(user_id) {
  try {
    const response = await axiosInstance.get(
      `/user/get_user_details_api/${user_id}`
    );

    if (response.status === 200 && response.data === "Missing JWT token") {
      getUserDataAPI(user_id);
    }

    return response;
  } catch (error) {
    throw error;
  }
}

// export async function getUserDataAPI(user_id) {
//   try {
//     const response = await axiosInstance.get(
//        `/user/get_user_details_api/${user_id}`
//     );
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export async function creatwNewApplicationIncreasedAPI() {
  try {
    const response = await axiosInstance.get(
      "/user/create_new_application_api"
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function checkFillAllTheDetailsDataAPI(token) {
  try {
    // Make a request using axios and include the token in the Authorization header
    const response = await axios.get(
      `${process.env.REACT_APP_AQATO_AGENT_APPLICANT_PORTAL_BASE_API_URL}/user/check_fill_all_the_details_api`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );

    if (response.status === 200 && response.data === "Missing JWT token") {
      // Retry the request if the JWT token is missing
      return checkFillAllTheDetailsDataAPI(token);
    }

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllCountryDataAPI() {
  try {
    const response = await axiosInstanceNoAuth.get("/get_country");

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllOccupationsAPI() {
  try {
    const response = await axiosInstanceNoAuth.get("/occupation_list");

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createOccupationDetails_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_occupation_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getOccupationDetails_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/occupation/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createPersonalDetails_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_personal_details_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPersonalDetails_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/personal_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createContactDetails_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_contact_details_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getContactDetails_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/contact_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createIdentification_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_identification_details_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getIdentification_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/identification_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createUSIandAvetmiss_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_usi_avetmiss__api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getUSIandAvetmiss_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/usi_avetmiss/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createEducationAndEmployment_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_employment_details__api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEducationAndEmployment_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/education_employment_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getReviewAndConfirm_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/stage_1_application_preview_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postReviewAndConfirm_stage1_Application_PDF_API(data) {
  try {
    const response = await axiosInstance.post(`/user/pdf_html_code_api`, data);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postReviewAndConfirm_CheckPDFDownloaded_stage1_Application_PDF_API(
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_1/check__review_confirm_pdf_download_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_CheckPDFDownloaded_ApplicationDeclaration_stage1_Application_PDF_API(
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_1/check__application_declare_pdf_download_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postApplicationDeclaration_CheckPDFDownloaded_stage1_Application_PDF_API(
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_1/review_confirm_pdf_download_check_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getApplicantDeclaration_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/stage_1_applicant_declaration_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postApplicantDeclaration_Files_by_parameter_stage1_API(
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_1/review_confirm_pdf_download_check_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/get_documents_stage_1_by_pointer_id/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function get_Is_doc_uploadExtraDocumentsList_API(
  pointer_id,
  stage
) {
  try {
    const response = await axiosInstance.get(
      `/user/comment_doc/getUploadedDocApi/${pointer_id}/${stage}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage1_get_assessments_docs_names(data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_1/get_stage1_all_docs`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function upload_get_extra_docs_names(pointer_id, stage) {
  try {
    const response = await axiosInstance.get(
      `/user/comment_doc/get_all_doc_stages/${pointer_id}/${stage}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_stage2_Assessment_API(
  pointer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/verify_docs_assessment/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_AdditionalRequest_Assessment_API(
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/get_uploaded_documents_additonal_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function FinalSubmissionAPIOfRequestData(data) {
  try {
    const response = await axiosInstance.post(
      `/user/mark_all_docs_done_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_stage2_Employment_API(
  pointer_id,
  employer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/verify_docs_employer/${pointer_id}/${employer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploaadDocuments_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1/stage_1_upload_documents_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploaad_Emplyment_Documents_stage2_API(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/employe_document_multiple_upload_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function upload_Assessment_Documents_stage2_API(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/assessment_documents_multiple_upload_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function upload_AdditionalRequest_API(data) {
  try {
    const response = await axiosInstance.post(
      `/user/upload_documents_additonal_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocument_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1/delet_file_",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocumentAPI(pointer_id, docId) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/delete_documents_common/${pointer_id}/${docId}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteExtraDocumentAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/user/comment_doc/deleteDocumentAndComments`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteAdditionalDocumentAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/user/delete_additional_doc_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsDetails_stage1_API(
  pointer_id,
  required_document_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/get_documents_stage_1_by_pointer/${pointer_id}/${required_document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function get_stage_2_DocumentsDetailsAPI(
  pointer_id,
  required_document_id,
  employee_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/get_document_by_id/${pointer_id}/${required_document_id}/${employee_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsDetailsAPI(
  pointer_id,
  required_document_id,
  stage
) {
  try {
    const response = await axiosInstance.get(
      `/common_get_document_by_required_id_api/${pointer_id}/${required_document_id}/${stage}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getExtra_DocumentsDetailsAPI(
  pointer_id,
  required_document_id
) {
  try {
    const response = await axiosInstance.get(
      `/common_get_document_by_id_api/${pointer_id}/${required_document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAdditionalRequestDocumentsDetailsAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/user/view_application_additional_get_document_by_id_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getForms_PDF_URL__API() {
  try {
    const response = await axiosInstance.get("/user/download_Form_api");

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getIncomplete_Application_API(data) {
  try {
    const response = await axiosInstance.post(
      `/user/common_get_user_side_pagination_incomplete_application`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteIncomplete_Application_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/incomplete_application_delete/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getIncomplete_Application_path_for_routing_API(
  pointer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/incomplete_application_route_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEmployment_Verification_Pending_API() {
  try {
    const response = await axiosInstance.get(`/user/pending_verification_api`);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEmployment_Verification_Pending_details_API(
  pointer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/pending_view_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSubmitted_Application_API(data) {
  try {
    const response = await axiosInstance.post(
      `/user/common_get_user_side_pagination_submitted_application`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function get_ViewApplications_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/view_application/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

// Stage 2 (R) API

export async function call_reinstate_stage_2_R_api(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2_R/apply_for_reassessment_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}



export async function uploadTheDocument_stage_2_R_api(pointer_id, data, onUploadProgress) {

  try {
    const response = await axiosInstance.post(
      `/user/stage_2_R/upload_document_upload_api/${pointer_id}`,
      data,
      {
        onUploadProgress, // Pass the progress callback to Axios
      }
    );

    return response;
  } catch (error) {
    throw error;
  }

}



export async function getDocumentBasedOnReqdID_stage_2_R_api(data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_R/get_document_based_on_required_id_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}


export async function delete_Document_Stage_2_R_api(document_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2_R/delete_document_api/${document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}




export async function FinalSubmission_Of_stage_2_R_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2_R/submit_application_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}








// END Stage 2 (R) API

export async function get_FinalPageApplications_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/Finish_application/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function triggerSendMailApi_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3_reassessment/start_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_add_employment_details(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_add_employment_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_update_employment_details(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_edite_employment_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEmploymentDetailsForTable_stage2_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/add_employment_document_page_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function get_One_EmploymentDetailsForTable_stage2_API(
  pointer_id,
  employee_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/get_single_employer/${pointer_id}/${employee_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_delete_employment_details(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_delete_employe_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchLocationsForStage_4(pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/receipt_upload/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchLocationsForStage_3(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3/get_all_preferred_location/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchLocationsForStage_3_R(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3_reassessment/get_all_preferred_location/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchAddressDetailsByAPIByCityname(data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/get_address`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function submitLocationOfStage_4(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/save_Preferred_location/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function submitLocationOfStage_3(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/save_preferred_location/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function submitLocationOfStage_3_R(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3_reassessment/save_preferred_location/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataOfStage_3_ByPointer_ID(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3/receipt_get_all_data/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataOfStage_3_R_ByPointer_ID(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3_reassessment/receipt_get_all_data/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataOfStage_4_ByPointer_ID(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_4/receipt_get_all_data/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocuments_stage4_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/upload_tra_payment_receipt/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocuments_stage3_API(
  pointer_id,
  required_document_id,
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/upload_document_file/${pointer_id}/${required_document_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadExtraDocuments_API(data) {
  try {
    const response = await axiosInstance.post(
      `/user/comment_doc/comment_file_upload`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocuments_stage3_R_API(
  pointer_id,
  required_document_id,
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3_reassessment/upload_document_file/${pointer_id}/${required_document_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_Practical_Interview_stage4_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/save_Preferred_info_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_FinalSubmission_Of_stage4_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/submit_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function FinalSubmission_Of_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/submit_stage_1_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function FinalSubmission_Of_stage2_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/final_stage_2_submit_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_FinalSubmission_Of_stage3_API(pointer_id, data) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3/submit_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_sendStatusOfExemptionFormDownloadedAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/user/common_update_exmption_form_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_FinalSubmission_Of_stage3_R_API(pointer_id, data) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3_reassessment/submit_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_submissionOf_Terms_Yes_Or_No_Of_stage3_API(
  pointer_id,
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/store_yes_no/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_submissionOf_Terms_Yes_Or_No_Of_stage3_R_API(
  pointer_id,
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3_reassessment/store_yes_no/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_Technical_Interview_stage3_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/save_stage_3_basic_info/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_Technical_Interview_stage3_R_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3_reassessment/save_stage_3_basic_info/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_get_organisations_names(pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/get_employment_document_list_/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_get_assessments_docs_names(data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/get_stage2_all_docs`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_get_employment_docs_names(pointer_id, employe_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/get_employment_documents_data/${pointer_id}/${employe_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

// React Component Example
const App = () => {
  // useEffect(() => {
  //   authorizeMe(); // Call this once when the app loads
  // }, []);

  return <>{/* Your App JSX goes here */}</>;
};

export default App;
export { axiosInstance, axiosInstanceNoAuth };
