import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { Col, Row, Button } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Create New Application.css"
import Heading from "../Heading/Heading";
import OccupationDetails_M from "./stage_1_middle_entry/Occupation Details/Occupation Details";
import PersonalDetails_M from "./stage_1_middle_entry/Personal Details/Personal Details";
import ContactDetails_M from "./stage_1_middle_entry/Contact Details/Contact Details";
import Identification_M from "./stage_1_middle_entry/Identification/Identification";
import USIAndAvetmiss_M from "./stage_1_middle_entry/USI & Avetmiss/USI & Avetmiss";
import EducationAndEmployment_M from "./stage_1_middle_entry/Education & Employment/Education & Employment";
import ReviewAndConfirm_M from "./stage_1_middle_entry/Review & Confirm/Review & Confirm";
import ApplicantDeclaration_M from "./stage_1_middle_entry/Applicant Declaration/Applicant Declaration";
import UploadDocuments_M from "./stage_1_middle_entry/Upload Documents/Upload Documents";
import Stepper_M from "./stage_1_middle_entry/Stepper/Stepper_M";
import Heading_M from "../Heading/Heading_M";


const CreateNewApplication_M = ({ onLogout }) => {

  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const pointerData = location.search.replace("?pointer_id=","");;



  const [clientNameInBanner, setClientNameInBanner] = useState('');
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const [showConfirmationModalForBack, setShowConfirmationModalForBack] =
    useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [logoutClicked, setLogoutClicked] = useState(false);
  const currentPath = location.pathname;

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleBack = () => {
    // setShowConfirmationModalForBack(true);
    // setBackClicked(true);

    navigate("/user/dashboard");

  };

  const handleConfirmationForBackYes = () => {
    navigate("/user/dashboard");
  };

  const handleConfirmationForBackNo = () => {
    setShowConfirmationModalForBack(false);
    setBackClicked(false);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  return (
    <>
      <Row className="mb-3">



      {/* <Col> */}
  {/* <Button
    className="back-button"
    variant=""
    onClick={handleBack}
    style={{
      // backgroundColor: "#f0f8ff",
      color: "#055837",
      marginLeft: "25px",
      outline: "none",
      boxShadow: "none",
    }}
  >
    <BsArrowLeft className="me-0" /> Back to Dashboard
  </Button> */}
{/* </Col> */}




        {/* <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col> */}
        <Heading_M text="Stage 1 - Self Assessment" clientNameInBanner={clientNameInBanner}/>
      </Row>
      

      <Stepper_M />
      {/* <Stepper /> */}
      

      {currentPath === "/user/middle_entry/stage_1/occupation_details" && <OccupationDetails_M pointerData={pointerData} setClientNameInBanner={setClientNameInBanner}/>}

      {currentPath === "/user/middle_entry/stage_1/personal_details" && <PersonalDetails_M />}

      {currentPath === "/user/middle_entry/stage_1/contact_details" && <ContactDetails_M />}

      {currentPath === "/user/middle_entry/stage_1/identification" && <Identification_M />}

      {currentPath === "/user/middle_entry/stage_1/usi_and_avetmiss" && <USIAndAvetmiss_M />}

      {currentPath === "/user/middle_entry/stage_1/education_employment" && <EducationAndEmployment_M />}

      {currentPath === "/user/middle_entry/stage_1/review_and_confirm" && <ReviewAndConfirm_M />}

      {/* {currentPath === "/user/middle_entry/stage_1/application_declaration" && <ApplicantDeclaration_M />}

      {currentPath === "/user/middle_entry/stage_1/upload_documents" && <UploadDocuments_M />} */}




      {logoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationLogoutNo}
          onConfirm={handleConfirmationLogoutYes}
          title="Confirm Logout ?"
          message="Are you sure you want to log out?"
        />
      )}

      {backClicked && (
        <ConfirmationModal
          show={showConfirmationModalForBack}
          onHide={handleConfirmationForBackNo}
          onConfirm={handleConfirmationForBackYes}
          title="Confirm Back"
          message="Going back will navigate you to the dashboard. Are you sure you want to abort the create new application process right now? "

        />
      )}
    </>
  );
};

export default CreateNewApplication_M;
