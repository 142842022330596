import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BsArrowLeft } from 'react-icons/bs'

export default function Custom_Ok_Popup() {

    const [popupOpen, setPopupOpen] = useState(true);

    return (
        <Modal show={popupOpen}
        onHide={() => {
            setPopupOpen(false);
        }}
            animation={false}
            backdrop="static"
            centered
            size="lg">
            <Modal.Header
            style={{
                backgroundColor: "#FFCC01",
                color: "#055837"
            }}
            >
                <Modal.Title className='mx-auto d-block'>
                    <h5 className="modal-title text-uppercase" id="exampleModalLabel"><b>Notice</b></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <div className="row">
                <div className="col-12 custom_text text-uppercase text-center" style={{fontWeight: 'bold', fontSize: 15}}>
                    <h5>
                        Kindly ensure you click on <b>“Submit Additional Information”</b> after all the requested documents have been uploaded to ensure the application can proceed further.
                    </h5>
                </div>
            </div>


            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="light"
                    className="btn_green_yellow"
                    style={{
                        backgroundColor: "#055837",
                        color: "#ffcc01",
                        transition: "transform 0.2s",
                    }}
                    onClick={() => {
                        setPopupOpen(false);
                        // get the scroll height of the window
                        const scrollHeight = document.body.scrollHeight;



                        // // scroll to the bottom of webpage
                        window.scrollTo(0, scrollHeight);

                    }}
                    onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
                    onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
